import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

export const CATEGORY_HOME_FREE_SESSION_BUTTON = {
  category: 'home_free_session_button',
  value: 101
}
export const CATEGORY_HOME_FREE_SESSION_BUTTON_MOBILE = {
  category: 'home_free_session_button_mobile',
  value: 102
}
export const CATEGORY_PACKAGE_BLOCK = {
  category: 'package_block',
  value: 103
}

export const CATEGORY_PACKAGE_BLOCK_NOT_SURE = {
  category: 'package_block_not_sure',
  value: 104
}

export const ACTION_CLICKED = 'Clicked'
export const ACTION_HOVERED = 'Hovered'

export const LABEL_HOME_FREE_SESSION_BUTTON = (action) => `Free Session ${action}`
export const LABEL_HOME_FREE_SESSION_BUTTON_MOBILE = (action) => `Free Session ${action} (Mobile)`

export const logGAEvent = ({category, action, label, value}) => {
  trackCustomEvent({
    category,
    action,
    label,
    value
  })
}
