import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
// import Img from 'gatsby-image';

import { Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

import WhatsappIcon from '@static/icons/whatsapp.png';
import TwitterIcon from '@static/icons/twitter.png';
import FacebookIcon from '@static/icons/facebook.png';
import LinkedInIcon from '@static/icons/linkedin.png';
import YoutubeIcon from '@static/icons/youtube.png';

function isMobile() {
  if(typeof window !== 'undefined') {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))
  }
  return false
}

const SOCIAL = [
  {
    icon: LinkedInIcon,
    link: 'https://www.linkedin.com/in/abhilasha-verman-783babb8',
    alt: 'LinkedIn'
  },
  {
    icon: FacebookIcon,
    link: 'https://www.facebook.com/admissionrushfb',
    alt: 'Facebook'
  },
  {
    icon: TwitterIcon,
    link: 'https://twitter.com/AdmissionRush',
    alt: 'Twitter'
  },
  {
    icon: YoutubeIcon,
    link: 'https://www.youtube.com/channel/UCdLANu38Z5Fzyma1FMjkfOA',
    alt: 'Youtube'
  },
  {
    icon: WhatsappIcon,
    link: isMobile() ? 'whatsapp://send?abid=%2B4915124867054&text=Hey%20AdmissionRush%2C%20please%20connect%20at%20the%20earliest.' :
      'tel:+4915124867054',
    alt: 'Instagram'
  }
];

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        art_pot: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "customers_pot" }
        ) {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <FooterWrapper>
          <StyledContainer>
            <Copyright>
              <h2 style={{marginBottom: 10}}>Admission Rush</h2>
              <div>
                <span>Write to us @ <a href="mailto:admissionrush@gmail.com">admissionrush@gmail.com</a></span>
              </div>
              <div>
                <span style={{fontSize: 12}}>© Copyright 2020. All Rights Reserved.</span>
              </div>
              {/* <span>
                Illustrations by
                {` `}
                <ExternalLink href="https://twitter.com/diana_valeanu">
                  @diana_valeanu
                </ExternalLink>
              </span> */}
            </Copyright>
            <SocialIcons>
              {SOCIAL.map(({ icon, link, alt }) => (
                <ExternalLink key={link} href={link}>
                  <img src={icon} alt={alt} />
                </ExternalLink>
              ))}
            </SocialIcons>
          </StyledContainer>
        </FooterWrapper>
      </React.Fragment>
    )}
  />
);

const SocialIcons = styled.div`
  display: flex;

  img {
    margin: 0 8px;
    width: 24px;
    height: 24px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-top: 40px;
  }
`;

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.color.primary};
  padding: 32px 0;
`;

const Copyright = styled.div`
  font-family: ${props => props.theme.font.secondary};
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.regular};

  a {
    text-decoration: none;
    color: inherit;
  }
`;

// const Art = styled.figure`
//   display: flex;
//   justify-content: center;
//   margin: 0;
//   margin-top: 48px;
// `;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
    text-align: center;
  }
`;

export default Footer;
