import React, { Component } from 'react';
// import AnchorLink from 'react-anchor-link-smooth-scroll';
// import Scrollspy from 'react-scrollspy';
import { StaticQuery, graphql } from 'gatsby';

import {
  logGAEvent,
  CATEGORY_HOME_FREE_SESSION_BUTTON,
  ACTION_CLICKED,
  ACTION_HOVERED,
  LABEL_HOME_FREE_SESSION_BUTTON
} from '@utils/analytics';
import { getRoute } from '@utils/device';
import { Section, Container } from '@components/global';
import PulsatingDot from '@components/common/Generic/PulsatingDot';
import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
} from './style';

import { ReactComponent as MenuIcon } from '@static/icons/menu.svg';
import LogoIcon from '@static/icons/wolf_logo.png';

const currentRoute = getRoute();
const NAV_ITEMS = [
  {
    title: 'Home',
    link: ''
  },
  {
    title: 'About Us',
    link: 'about'
  },
  {
    title: 'Our Services',
    link: 'services'
  },
  {
    title: 'News Room',
    link: 'news'
  },
  {
    title: 'Free Profile Evaluation',
    link: 'contact',
    animate: true,
    highlight: true
  }
];

class Navbar extends Component {
  state = {
    mobileMenuOpen: false,
  };

  toggleMobileMenu = () => {
    this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }));
  };

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false });
    }
  };

  getNavAnchorLink = ({ title, link, highlight }) => {
    const onClick = link === 'contact' ? () => this.sendGAEvent({ action: ACTION_CLICKED }) : this.closeMobileMenu
    const onMouseEnter = link === 'contact' ? () => this.sendGAEvent({ action: ACTION_HOVERED }) : () => {}
    return <a href={`/${link.toLowerCase()}`} onClick={onClick} onMouseEnter={onMouseEnter}>
      {highlight ? <span style={{paddingLeft: 24}}></span> : ''}
      {title}
      {highlight ? <span style={{paddingRight: 6}}></span> : ''}
    </a>
  }

  sendGAEvent = ({ action }) => {
    logGAEvent({
      category: CATEGORY_HOME_FREE_SESSION_BUTTON.category,
      action,
      label: LABEL_HOME_FREE_SESSION_BUTTON(action),
      value: CATEGORY_HOME_FREE_SESSION_BUTTON.value
    })
  }

  renderNavItem = (navItem) => {
    return <div key={navItem.link}>
      <NavItem key={navItem.link} animate={navItem.animate} highlight={navItem.highlight}>
        {navItem.highlight ? <div style={{
          display: 'inline',
          left: '-18px',
          top: '-12px',
          position: 'relative'
        }}><PulsatingDot /></div> : null}
        {this.getNavAnchorLink(navItem)}
      </NavItem>
    </div>
  }

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      {/* <Scrollspy
        items={NAV_ITEMS.map(({title, link}) => title.toLowerCase())}
        currentClassName="active"
        mobile={mobile}
        offset={-64}
      > */}
        {NAV_ITEMS.map(navItem => this.renderNavItem(navItem))}
      {/* </Scrollspy> */}
    </NavListWrapper>
  );

  render() {
    const { mobileMenuOpen } = this.state;

    return <StaticQuery
      query={graphql`
        query {
          ieca: file(
            sourceInstanceName: { eq: "logos" }
            name: { eq: "ieca" }
          ) {
            childImageSharp {
              fluid(maxWidth: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <Section id="home" accent style={{padding: 0}}>
          <Nav {...this.props}>
            <StyledContainer>
              <Brand onClick={() => {window.location = '/#'}} style={{cursor: 'pointer'}}>
                <div>
                  <img src={LogoIcon} style={{width: 46, float: 'left'}} />
                  <div style={{float: 'right', marginLeft: 12}}>Admission<br />Rush</div>                    
                </div>
              </Brand>
              
              <Mobile>
                <button onClick={this.toggleMobileMenu} style={{ color: 'black' }}>
                  <MenuIcon />
                </button>
              </Mobile>

              <Mobile hide>{this.getNavList({})}</Mobile>
            </StyledContainer>
            <Mobile>
              {mobileMenuOpen && (
                <MobileMenu>
                  <Container>{this.getNavList({ mobile: true })}</Container>
                </MobileMenu>
              )}
            </Mobile>
          </Nav>
        </Section>
      )}
    />;
  }
}

export default Navbar;
