import styled, { css } from 'styled-components';

import { Container } from '@components/global';

export const Nav = styled.nav`
  padding: 16px 0;
  background-color: ${props => props.theme.color.white.regular};
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0 6px 26px 0 rgba(0,0,0,.2);
`;

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavListWrapper = styled.div`
  display: inline-flex;
  list-style: none;

  ${({ mobile }) =>
      mobile &&
      `
        display: inline;
        background-color: #000;
      `};

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;

    ${({ mobile }) =>
      mobile &&
      `
        flex-direction: column;
        margin-top: 1em;

        > ${NavItem} {
          margin: 0;
          margin-top: 0.75em;
        }
      `};
  }
`;

// const zoomout = keyframes`
//   from {
//     transform: scale(1);
//   }

//   to {
//     transform: scale(0.95);
//   }
// `;


const highlight = css`
  border-radius: 25px;
  padding: 2px 8px 2px 8px;
`;

export const NavItem = styled.li`
  margin: 0 0.75em;
  font-family: ${props => props.theme.font.secondary};
  ${props => props.highlight ? props.theme.font_size.verysmall : props.theme.font_size.small};
  line-height: 30px;

  margin-top: ${props => props.animate ? '-3px': 0};
  ${props => (props.highlight ? highlight : '')}
  background-color: ${props => props.highlight ? 'rgba(0, 0, 0, 0.7)' : 'none'};

  a {
    text-decoration: none;
    opacity: ${props => props.highlight ? 1 : 0.7};
    color: ${props => props.highlight ? props.theme.color.white.regular : props.theme.color.black.regular};
  }

  &.active {
    a {
      opacity: 1;
    }
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    display: ${props => props.highlight ? 'none' : 'block'};
  }
`;

export const MobileMenu = styled.div`
  width: 100%;
  padding-top: 20px;
`;

export const Brand = styled.div`
  font-family: ${props => props.theme.font.primary};
  font-size: 24px;
  font-weight: 900;

  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
  }
`;

export const Mobile = styled.div`
  display: none;

  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
  }

  ${props =>
    props.hide &&
    `
    display: block;

    @media (max-width: ${props.theme.screen.md}) {
      display: none;
    }
  `}
`;
